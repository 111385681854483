.languageContainer {
    position: absolute;
    background-color: #fff;
    display: flex;
    align-items: center;
    top: 2px;
    gap: 5px;
    padding: 5px;
    margin: 20px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;

    z-index: 10000000000;
}

.right {
    right: 0;
}

.left {
    left: 0;
}

.languageContainer i {
    font-size: 20px;
    cursor: pointer;
    color: #094063;
}

.languageContainer select {
    border: none;
    background-color: transparent;
    color: #333;
}

.loader-fb {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 400px) {
    .lang__icon {
        display: none;
    }

    .languageContainer {
        top: 30px;
    }
}
