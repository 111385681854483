.modalP {
    width: 105%;
    height: 94vh;
    top: -5rem;
    left: -2rem;
    position: absolute;
    background: rgba(49, 49, 49, 0.8);

    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-cont {
    line-height: 1.4;
    background: #f1f1f1;
    padding: 54px 28px;
    border-radius: 3px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.coming-soon_img {
    width: 40%;
    min-width: 12rem;
}

.coming-soon_text {
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 1.7rem;
    text-align: center;
}

.close-modal {
    color: #4f4f4f;
    font-size: 28px;
    font-weight: bold;
    align-self: flex-end;
}

.closeBtn-modal {
    padding: 15px;
    display: block;
    font-size: 16px;
    background: #094063;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;

    border: none;
}
.language-switch {
    align-self: flex-start;
    border: 1px solid red;
}

@media screen and (max-width: 950px) {
    .closeBtn-modal {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 768px) {
    .modalP {
        width: 100%;
        left: 0;
    }
}

@media screen and (max-width: 500px) {
    .closeBtn-modal {
        font-size: 1.2rem;
    }

    .coming-soon_text {
        font-size: 1rem;
    }

    .modal-cont {
        width: 80%;
    }
}
